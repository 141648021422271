


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import {BASE_API_URL, EventBus} from "@/config";
import { VueEditor } from "vue2-editor";
import striptags from "striptags";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import TextEditor from "@/views/TextEditor.vue";
import _ from "lodash";

@Component({ components: { VueEditor, TextEditor } })
export default class EmailReply extends Vue {
  public token = null;
  public messageData: any = null;
  public message = null;
  public file: any = [];
  public isEmailHasBody = false;
  public inProcess = false;
  public isLenderResponded = false;
  public messageAttachment: any = [];
  public messageBody: any = null;
  public originalMessage = null;
  public fromName = null;
  public emptyMessage = false;
  public errorSendFileMessage = null;
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public fileForDelete = {};
  public fileInput = null;
  public originalMessageExpanded: boolean = false;
  public originalMessageHasScrollbar: boolean = false;

  $refs!: {
    originalMessage: any,
  };

  get messageTo() {
    return _.get(this.messageData, 'fromName', '');
  }

  get computedTitleFrom() {
    const append = ` from ${ this.messageTo }`
    return `E-mail ${ this.messageTo ? append : ''}`
  }

  get commonTooltipProps() {
    return {
      placement: 'top',
      classes: 'tooltipReplyFormTopPosition',
      delay: { show: 200, hide: 200 }
    }
  }

  checkIfOriginalMessageHasScrollbar() {
    const originalMessageElement = this.$refs.originalMessage;

    if (!originalMessageElement) {
      this.originalMessageHasScrollbar = false;
    }

    setTimeout(function() {
      this.originalMessageHasScrollbar = originalMessageElement.scrollHeight > originalMessageElement.clientHeight;
    }.bind(this), 100);
  }

  public async verifyEmailReplyToken() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "authentication/verifyEmailReplyToken",
        {
          token: this.token,
          userId: this.$route.query.id
        }
      );
      if (response.data.length > 0) {
        this.messageData = response.data[0];
        response.data[0].to.forEach(e => {
          if (e.userId == this.$route.query.id) {
            this.fromName = e.toName;
          }
        });
        this.originalMessage = response.data[0].body;
        this.checkIfOriginalMessageHasScrollbar();
        let stripedMessage = striptags(response.data[0].body, [], " ");
        this.messageBody = stripedMessage.replace(/\s\s+/g, " ");
        this.messageAttachment = response.data[0].attachments;
      } else {
        this.$snotify.error("Token has expired Please contact to admin");
        this.$router.push("/");
      }
    } catch (error) {
      console.log(error);
    }
  }

  toggleOriginalMessageExpanded() {
    this.originalMessageExpanded = !this.originalMessageExpanded;
  }

  setFileInput() {
    this.fileInput = document.documentElement.querySelector('.vue-file-agent .file-input');
  }

  public onPaperclipHandler() {
    if (!this.fileInput) {
      this.setFileInput();
    }

    this.fileInput?.click();
  }

  // for incoming attachments, temporary disabled
  // because unauthorized users should not be able to access the platform files
  public showMessageAttachments() {
    this.$modal.show('toSeeAttachment', {
      type: 'incoming',
      file: this.file,
      currentFileIndex: 0,
      messageAttachment: this.messageAttachment,
    });
  }

  public showReplyAttachments(index) {
    const currentFileIndex = index;
    this.$modal.show('toSeeAttachment', { type: 'outgoing', file: this.file, currentFileIndex });
  }

  public async sendReplyEmail() {
    let formValid = await this.$validator.validateAll();
    this.inProcess = true;
    let self = this;
    let loader = this.$loading.show();
    try {
      let formData = new FormData();
      this.file.forEach((element: any) => {
        formData.append("file", element);
      });
      if (!formValid) {
        this.isEmailHasBody = true;
        this.inProcess = false;
        loader.hide();
        this.emptyMessage = true;
        return;
      }
      //to send the response to broker
      let dataToServer = {
        message: this.message,
        token: this.token,
        userId: this.$route.query.id
      };
      formData.append("data", JSON.stringify(dataToServer));
      Axios.post(
        BASE_API_URL + "authentication/sendReplyEmail",
        formData
      )
      .then(function (response) {
        if (response.data.status == "success") {
          self.isLenderResponded = true;
          self.$snotify.success("Message Sent Successfully");
        }
      })
      .catch((error) => {
        console.log(error);
        self.errorSendFileMessage = _.get(error, 'response.data.message', '');
      })
      .finally( () => {
        self.inProcess = false;
        loader.hide();
      });
    } catch (error) {
      this.inProcess = false;
      loader.hide();
      console.log(error);
    }
  }

  public previewUploadFile(fileInfo) {
    const index = this.file.findIndex(f => f.name == fileInfo.name && f.size == fileInfo.size);
    if (index < 0 || this.isFileHasUploadError(this.file[index].name)) return;
    this.showReplyAttachments(index)
  }

  public isFileHasUploadError(fileName) {
    const fileInfo = this.file.find(f => f.name == fileName);
    if (!fileInfo) return;
    if (_.get(fileInfo, 'errorMessage')) return true;
  }

  public setFileForDelete(file) {
    this.fileForDelete = file
  }

  async mounted() {
    this.setFileInput();
    EventBus.$on('file-for-delete', this.setFileForDelete)

    this.token = this.$route.query.token;
    await this.verifyEmailReplyToken();
  }

  beforeDestroy() {
    EventBus.$off('file-for-delete', this.setFileForDelete)
  }
}
