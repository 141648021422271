

import { Vue, Component } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import { VueEditor } from "vue2-editor";
import striptags from "striptags";
import saveAs from "save-as";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";


@Component({ components: { VueEditor } })
export default class ScenarioDeskResponse extends Vue {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public isEmailHasBody = false;
  public inProcess = false;
  public scenarioDeskSender: any = {}; //it contain all the information of e-mail like selected lenders ,sendingdate, message, loantxnid ,subject of email
  public message = null;
  public token: any = null;
  public type = "response";
  public fileType = null;
  public fileToDisplay = null;
  public currentFileIndex = 0;
  public showNextButton = false;
  public showPreviousButton = false;
  public file: any = [];
  public isLenderResponded = false;
  public dotLoader: any = null;
  public responseFor = null;
  public messageFromBroker = null;
  public fullMessage = null;
  public senderName = "";
  public subject = null;
  public attachment: any = [];
  //Same syntax issue as mentioned earlier.
  public customToolBar = [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }]
  ];
  public async verifyScenarioDeskToken() {
    let response = await Axios.post(
      BASE_API_URL + "broker/verifyScenarioDeskToken",
      {
        token: this.$route.query.token,
        id: this.$route.query.id
      }
    );

    if (response.data.status == "success") {
      this.scenarioDeskSender = response.data.scenarioData;
      this.attachment = response.data.attachment;
      this.subject = response.data.subject;
      this.responseFor = response.data.index;

      let stripedMessage = striptags(response.data.message, [], " "); //To strip html into normal text.
      stripedMessage = stripedMessage.replace(/\s\s+/g, " "); //To remove multiple white spaces as above function create multiple white spaces in case of <ol> and <ul> tags.
      this.messageFromBroker = stripedMessage;
      this.fullMessage = response.data.message;
      this.senderName = response.data.senderName;
    } else {
      this.$snotify.error(response.data.message);
      this.$router.push("/");
    }
  }

  public async toSeeFullMessage() {
    let scenarioReply = document.getElementById("modal-Fixed");
    scenarioReply.classList.add("modal-fixed");
    this.$modal.show("toSeeFullMessage");
  }
  public hideFullMessageModal() {
    let scenarioReply = document.getElementById("modal-Fixed");
    scenarioReply.classList.remove("modal-fixed");
    this.$modal.hide("toSeeFullMessage");
  }
  public toHideAttachmentModal() {
    let scenarioReply = document.getElementById("modal-Fixed");
    scenarioReply.classList.remove("modal-fixed");
    this.$modal.hide("toSeeAttachment");
  }
  public async toSeeAttachment(action = null) {
    let scenarioReply = document.getElementById("modal-Fixed");
    scenarioReply.classList.add("modal-fixed");
    if (action == "previous") {
      this.currentFileIndex--;
    }
    if (action == "next") {
      this.currentFileIndex++;
    }

    this.file.forEach((element, i) => {
      if (this.currentFileIndex == i) {
        this.fileToDisplay = URL.createObjectURL(element);
        let file = element.name.split(".");
        this.fileType = file[file.length - 1];
      }
      if (this.file.length == 1) {
      } else if (this.currentFileIndex == 0) {
        this.showPreviousButton = false;
        this.showNextButton = true;
      } else if (this.currentFileIndex == this.file.length - 1) {
        this.showPreviousButton = true;
        this.showNextButton = false;
      } else {
        this.showPreviousButton = true;
        this.showNextButton = true;
      }
    });

    if (!action) this.$modal.show("toSeeAttachment");
  }

  private async downloadAttachment(path) {
    try {
      this.$store.state.wemloLoader = true;
      let ext = path.split('.').pop();
      let response = await Axios.post(BASE_API_URL+'common/fetchDocumentOnce', { path: path }, { responseType: 'blob'});
      let fileName = path.split('/').pop().split('.');
      fileName.pop();
      this.$store.state.wemloLoader = false;
      saveAs(response.data, fileName+`.${ext}`);
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }


  public downloadFile(file) {
    file = this.file[this.currentFileIndex];
    saveAs(file, file.name);
  }
  public storedFile(event) {
    this.file = [];
    Object.values(event.target.files).forEach(element => {
      this.file.push(element);
    });
  }
  public clearAttachment(fileToDisplay) {
    if (this.file.length == 1) {
      let mailAttachment: any = this.$refs.mailAttachment;
      mailAttachment.value = null;
    }
    this.file.splice(this.currentFileIndex, 1);
    this.currentFileIndex = 0;
    if (this.file.length == 1) {
      this.showNextButton = false;
    }
    this.toSeeAttachment();

    if (this.file.length == 0) {
      this.currentFileIndex = 0;
      this.$modal.hide("toSeeAttachment");
    }
  }

  public async sendEmail() {
    try {
      if (this.message == "" || this.message == null) {
        this.isEmailHasBody = true;
        this.inProcess = false;
        this.$store.state.wemloLoader = false;
        return;
      }

      this.inProcess = true;
      
      this.$store.state.wemloLoader = true;
      let formData = new FormData();
      this.file.forEach((element: any) => {
        formData.append("file", element);
      });

      //to send the response to broker
      let dataToServer = {
        message: this.message,
        token: this.token,
        scenarioDeskSender: this.scenarioDeskSender,
        type: this.type,
        responseFor: this.responseFor
      };
      formData.append("data", JSON.stringify(dataToServer));
      let response = await Axios.post(
        BASE_API_URL + "broker/sendResponseToBroker",
        formData
      );

      if (response.data.message == "success") {
        this.isLenderResponded = true;

        this.$snotify.success("Message Sent Successfully");
      }
      this.inProcess = false;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.inProcess = false;
      this.$store.state.wemloLoader = false;
    }
  }

  

  mounted() {
    this.token = this.$route.query.token;
    this.verifyScenarioDeskToken();
  }
}
