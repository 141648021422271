


import stores from "@/store/store";
const store: any = stores;
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import { ACCESS_RIGHTS_COMPONENTS } from "../../../constants";
import { HollowDotsSpinner } from "epic-spinners";
import UpdateUserInfo from "@/views/UpdateUserInfo.vue";

@Component({ components: { HollowDotsSpinner, UpdateUserInfo } })
export default class listUserAccessComponent extends Vue {
  public currentUserData: any = {};
  public users: any = [];
  public modalUser: any = {};
  public initialEmail = null;
  public inProcess = false;
  public duplicateEmail = null;
  public emailExistence = false;
  public addMLO = false;
  public currentUserName = null;
  public dotLoader = false;
  public sameEmail = false;
  public callUpdateUserInfo = false;
  public userInfo = [];
  public searchList = [];
  public searchName = "";
  public allUsers = [];
  public userLoader = false;
  public loadMoreScroll = false;
  public initialPagination = { start: 0, end: 10 };
  public pagination: any = JSON.parse(JSON.stringify(this.initialPagination));
  public currentLengthOnScroll = 0;
  public totalStaff = 0;
  public skip = 0;
  public searchByType = null;
  public searchLoanString = "";
  public updated = false;
  public inProcessInfo = false;
  public index = 0;
  public get access() {
    return store.state.sessionObject.access;
  }
  public set access(data) {
    store.state.sessionObject.access = data;
  }

  // get users-mlos, adminstaff list
  public async getListForAccessRights(mountCall: boolean = false) {
    const limit = 10;
    this.skip = mountCall ? 0 : this.skip + limit;
    if (mountCall) {
      this.userLoader = true;
    }
    try {
      let response;
      response = await Axios.post(
        BASE_API_URL + "broker/listUsersForAccess/",
        {
          brokerId: this.$brokerId || this.$userId,
          userId: this.$userId,
          fieldName: this.searchByType,
          userType: this.$userType,
          options: {
            skip: this.skip,
            limit,
          },
          searchString:
            this.searchLoanString != "" && this.searchLoanString != null
              ? this.searchLoanString
              : null,
        });

      response.data.staffsData = JSON.parse(
        JSON.stringify(response.data.staffsData)
      );
      this.totalStaff = response.data.totalStaff;
      let users = response.data.staffsData;
      users.forEach(element => {
        if (element.user.userAccess.length == 0) {
          element.user.userAccess = ACCESS_RIGHTS_COMPONENTS;
        }
      });
      users = JSON.parse(JSON.stringify(users));
      this.callUpdateUserInfo = false;

      this.users = mountCall ? users : this.users.concat(users);
      this.allUsers = mountCall ? users : this.allUsers.concat(users);

      this.searchList = users;
      this.currentLengthOnScroll = this.users.length;
      this.updateAccessRight();
    } catch (error) {
      console.error(error.response);
    } finally {
      this.userLoader = false;
      this.loadMoreScroll = false;
    }
  }

  public updateAccessRight() {
    let index = this.users.findIndex(a => a._id == this.$userId);
    if (index != -1) {
      this.access = this.users[index].user.userAccess;
    }
  }
  public viewModal(user, type, modalName) {
    this.currentUserData = {};
    this.currentUserName = null;
    this.currentUserData = Object.assign({}, user.ac);
    if (type == "edit") {
      if (user.user.userType == "MLO") {
        this.addMLO = true;
        this.currentUserName = "MLO";
      } else if (user.user.userType == "Admin Staff") {
        this.currentUserName = "Admin Staff";
        this.addMLO = false;
      }
    } else if (type == "add") {
      if (user.userType == "MLO") {
        this.addMLO = true;
        this.currentUserName = "MLO";
      } else if (user.userType == "Admin Staff") {
        this.currentUserName = "Admin Staff";
        this.addMLO = false;
      }
    } else if (type == "view") {
      if (user.user.userType == "MLO") {
        this.addMLO = true;
        this.currentUserName = "MLO";
      } else if (user.user.userType == "Admin Staff") {
        this.currentUserName = "Admin Staff";
        this.addMLO = false;
      }
    }

    this.$modal.show(modalName);
  }

  public hideModal(modalName) {
    this.currentUserData = {};
    this.currentUserName = null;
    this.updated = false;

    this.addMLO = false;
    this.duplicateEmail = null;
    this.$modal.hide(modalName);
  }

  public async validateAndSubmit() {
    try {
      let access = JSON.parse(JSON.stringify(ACCESS_RIGHTS_COMPONENTS));
      let formValid = await this.$validator.validateAll();
      if (
        formValid &&
        this.currentUserData.email != this.duplicateEmail
      ) {
        this.currentUserData.firstName =
          this.currentUserData.firstName.charAt(0).toUpperCase() +
          this.currentUserData.firstName.substring(1).toLowerCase();
        this.currentUserData.lastName =
          this.currentUserData.lastName.charAt(0).toUpperCase() +
          this.currentUserData.lastName.substring(1).toLowerCase();
        this.currentUserData.email = this.currentUserData.email.toLowerCase();
        this.currentUserData.brokerId = this.$brokerId;
        this.currentUserData.invitee = this.$userId; /*sending broker userId in mlo db brokerid field */
        this.currentUserData["checkDuplicateEmail"] = true;
        this.currentUserData["accessRights"] = access;
        let url = this.addMLO ? "broker/addMLO" : "broker/addAdminStaff";
        let response = await Axios.post(
          BASE_API_URL + url,
          this.currentUserData);
        if (response.data.status == "success") {
          this.$snotify.success(this.currentUserName + " added successfully");
          this.duplicateEmail = null;
          this.hideModal("addusermodal");
          this.getListForAccessRights(true);
        } else if (response.data.status == "duplicateEmail") {
          this.duplicateEmail = this.currentUserData.email;
        }
      }
    } catch (error) {
      this.$snotify.error(error.response.message);
    }
  }

  mounted() {
    this.getListForAccessRights(true);
  }
  checkmarkStyle(type: any) {
    if (type == "MLO") {
      return "blue_user";
    } else if (type == "Admin Staff") {
      return "checkmark1";
    } else {
      return "checkmark2";
    }
  }
  getComponentIndex(index: any, name: any) {
    return this.users[index].user.userAccess.findIndex(
      (access: any) => access.component == name
    );
  }

  getIndex(index: any, name: any, event: any) {
    if (name == "allAccess" && event.target.checked == true) {
      this.users[index].user.userAccess.forEach((element: any) => {
        if (
          !(
            element.component == "startNewLoan" &&
            this.users[index].user.userType == "Admin Staff"
          )
        )
          element.canAccess = true;
      });
    } else if (name == "allAccess" && event.target.checked == false) {
      this.users[index].user.userAccess.forEach((element: any) => {
        return (element.canAccess = false);
      });
    } else if (name != "allAccess" && event.target.checked == false) {
      return (this.users[index].user.userAccess[5].canAccess = false);
    } else {
    }
  }

  mergeObjects(userAccess: any) {
    let mergeTo = JSON.parse(JSON.stringify(ACCESS_RIGHTS_COMPONENTS));
    userAccess.forEach((access: any) => {
      let index = mergeTo.findIndex(
        (i: any) => i.component == access.component
      );
      mergeTo[index].canAccess = access.canAccess;
    });
    return mergeTo;
  }

  public updateEmail(user) {
    this.callUpdateUserInfo = true;
    this.userInfo = [];
    let data = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      userId: user.user._id,
      userType: user.user.userType,
      modalName: "emailUpdate"
    };
    this.userInfo.push(data);
    this.$refs.updateUserStatus["openModalOrClose"](this.userInfo);
  }

  public async submitDataForAccessRights() {
    try {
      this.inProcess = true;
      let response = await Axios.post(
        BASE_API_URL + "broker/addAccessRights",
        {
          users: this.users,
          brokerId: this.$brokerId,
          manageUser: true
        });
      this.$snotify.success("Access Rights Saved Successfully");
      this.inProcess = false;
      this.getListForAccessRights(true);
    } catch (error) {
      console.log(error.response);
    }
  }

  //Function For Updating The User Info
  public async updateUser() {
    try {
      this.inProcessInfo = true;
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        this.inProcessInfo = false;
        return;
      }
      this.currentUserData.firstName =
        this.currentUserData.firstName.charAt(0).toUpperCase() +
        this.currentUserData.firstName.substring(1).toLowerCase();
      this.currentUserData.lastName =
        this.currentUserData.lastName.charAt(0).toUpperCase() +
        this.currentUserData.lastName.substring(1).toLowerCase();
      this.currentUserData.email = this.currentUserData.email.toLowerCase();
      let response = await Axios.post(
        BASE_API_URL + "broker/updateUser",
        { data: this.currentUserData, userType: this.currentUserName });
      this.$snotify.success(this.currentUserName + " updated successfully");
      this.currentUserData = {};
      this.currentUserName = null;
      this.updated = true;
      this.hideModal("editUser");
      this.inProcessInfo = false;
      this.getListForAccessRights(true);
    } catch (error) {
      this.inProcessInfo = false;
      this.$snotify.error("Error occurred during updating user");
    }
  }

  public getValueOnChange() {
    this.searchLoanString = null;
    if (this.searchByType == null) {
      this.getListForAccessRights(true);
    }
  }
  public async updateuserInfo(updatedData) {
    await this.getListForAccessRights(false);
  }

  public async showMoreDataOnScroll() {
    if (!this.loadMoreScroll && this.currentLengthOnScroll < this.totalStaff) {
      this.loadMoreScroll = true;
      await this.getListForAccessRights(false);
    }
  }
}
